import {axisBottom, axisLeft} from 'd3-axis'
import {controller, target} from '@github/catalyst'
import {scaleBand, scaleLinear, scaleOrdinal} from 'd3-scale'
import d3Tip from '../d3/tip'
import {max as d3max} from 'd3-array'
import {fetchPoll} from '../fetch'
import {select} from 'd3-selection'
import {stack} from 'd3-shape'
import {utcFormat} from 'd3-time-format'

interface GroupData {
  group: string
  anonymous: number
  'logged in': number
}

interface GraphData {
  date: number
  value: number
  data?: GroupData
}

type GroupedData = GroupData[]
type Data = GraphData[]
type GraphBars = Array<{class: string; name: string; delta: number; data: Data}>

async function fetchData(url: string): Promise<Response> {
  const response = await fetchPoll(url, {headers: {accept: 'application/json'}})
  return await response
}

@controller
class DiscussionPageViewsGraphElement extends HTMLElement {
  @target graph: HTMLElement

  async connectedCallback() {
    const graph = this.graph
    const url = graph.getAttribute('data-url')
    if (!url) {
      return
    }

    graph.classList.add('is-graph-loading')
    graph.classList.remove('is-graph-load-error', 'is-graph-empty')

    try {
      const response = await fetchData(url)

      graph.classList.remove('is-graph-loading')
      const data = await response.json()

      const observer = new ResizeObserver(entries => {
        for (const el of graph.querySelectorAll('svg.viz')) {
          el.remove()
        }

        for (const entry of entries) {
          if (entry.target === graph) {
            render(graph, data)
          }
        }
      })
      observer.observe(graph)
    } catch (error) {
      graph.classList.remove('is-graph-loading')

      if (error.response && error.response.status === 404) {
        graph.classList.add('is-graph-empty')
      } else {
        graph.classList.add('is-graph-load-error')
        throw error
      }
    }
  }
}

function render(container: HTMLElement, dataset: GraphBars) {
  const width = container.clientWidth
  const height = container.clientHeight

  const margin = {top: 50, right: 20, bottom: 40, left: 50}
  const calculatedWidth = width - margin.left - margin.right
  const calculatedHeight = height - margin.top - margin.bottom
  const spacer = 2 // the distance between the stacked bars

  const shortFormat = utcFormat('%-m/%-d')

  const tip = d3Tip<GraphData>()
    .attr('class', 'svg-tip')
    .offset([-10, 0])
    .html(function (d) {
      // eslint-disable-next-line github/unescaped-html-literal
      return `<strong>${d.data!.group}</strong><br>
      ${d.data!['anonymous']} anonymous visits<br>
      ${d.data!['logged in']} logged in visits`
    })

  // base graph
  const svg = select(container)
    .append('svg')
    .attr('class', 'viz')
    .attr('width', width)
    .attr('height', height)
    .attr('alt', 'A graph of the page views for discussions in this repository')
    .attr('aria-describedby', 'discussion-page-views-description')
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)
    .call(tip)

  let description = ''
  for (const ds of dataset) {
    description += `${ds.name}: `

    for (const d of ds.data) {
      description += `${d.value} on ${shortFormat(new Date(d.date))} `
    }
  }

  svg
    .append('div')
    .attr('text', description)
    .attr('style', 'display: none;')
    .attr('id', 'discussion-page-views-description')

  // legend
  for (const d of dataset) {
    svg
      .append('circle')
      .attr('cx', calculatedWidth - d.delta)
      .attr('cy', -margin.top / 2)
      .attr('class', d.class)
      .attr('r', 5)

    svg
      .append('text')
      .attr('x', calculatedWidth - d.delta + 10)
      .attr('y', -margin.top / 2 + 5)
      .attr('class', 'legend-text')
      .text(d.name)
  }

  // need to get the max for both sets of data, and sum them
  const max = d3max(dataset[0].data.map(c => c.value))! + d3max(dataset[1].data.map(c => c.value))!

  // x-axis
  const xTickValues = dataset[0].data.map(c => shortFormat(new Date(c.date)))
  const x = scaleBand<string>().domain(xTickValues).range([0, calculatedWidth]).padding(0.2)
  svg
    .append('g')
    .attr('transform', `translate(0, ${calculatedHeight})`)
    .attr('class', 'tick-labels-x')
    .call(axisBottom(x).tickSize(0).tickValues(xTickValues))

  // y-axis
  const y = scaleLinear().domain([0, max]).range([calculatedHeight, 0])
  svg.append('g').attr('class', 'tick-labels-y').call(axisLeft(y).ticks(4).tickSize(0))

  // grid for y
  svg
    .append('g')
    .attr('class', 'tick-y')
    .call(
      axisLeft(y)
        .ticks(4)
        .tickSize(-calculatedWidth)
        .tickFormat(() => {
          return ''
        })
    )

  // x-axis label
  svg
    .append('text')
    .attr('class', 'axis-label')
    .attr('text-anchor', 'middle')
    .attr('x', calculatedWidth / 2)
    .attr('y', height - margin.top)
    .text('Timeline')

  // y-axis label
  svg
    .append('text')
    .attr('class', 'axis-label')
    .attr('transform', 'rotate(-90)') // this rotation changes the x,y below
    .attr('x', -calculatedHeight / 2)
    .attr('y', -margin.bottom - 10)
    .attr('dy', '0.71em')
    .style('text-anchor', 'middle')
    .text('Quantity')

  const groupedData: GroupedData = []
  for (const [i, date] of dataset[0].data.map(d => d.date).entries()) {
    groupedData.push({
      group: shortFormat(new Date(date)),
      'logged in': dataset[0].data[i].value,
      anonymous: dataset[1].data[i].value
    })
  }

  // bars
  const subgroups = dataset.map(d => d.name)
  const color = scaleOrdinal<string>()
    .domain(subgroups)
    .range(dataset.map(d => d.class))
  const stackedData = stack<GroupData>().keys(subgroups)(groupedData)

  // Show the bars
  svg
    .append('g')
    .selectAll('g')
    // Enter in the stack data = loop key per key = group per group
    .data(stackedData)
    .join('g')
    .attr('class', function (d) {
      return color(d.key)
    })
    .selectAll('rect')
    // enter a second time = loop subgroup per subgroup to add all rectangles
    .data(d => d)
    .join('rect')
    .attr('x', d => x(d.data.group)!)
    .attr('y', function (d) {
      if (d[0] === 0) {
        return y(d[1])! + spacer
      }
      return y(d[1])!
    })
    .attr('height', function (d) {
      if (d[0] === 0) {
        return y(d[0])! - y(d[1])! - spacer
      }
      return y(d[0])! - y(d[1])!
    })
    .attr('width', x.bandwidth())
    .on('mouseover', tip.show)
    .on('mouseout', tip.hide)
}
